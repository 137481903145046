body {
  font-size: base('font-size');
  font-family: $montaga;
  line-height: base('line-height');
  color: color('body');
  background-color: white;

  &.is-sticky {
    padding-top: 4.125em;

    // Medium Desktops
    @include breakpoint(medium) {
      padding-top: 7.75em;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  width: 100%;
}

hr {
  height: 1px;
  margin: { top: 1.875em; bottom: 1.875em; }
  border: 0;
}

[class*='col-'] {
  img {
    display: block;
    margin: { bottom: 1.875em; left: auto; right: auto; }
  }
}
