.c-main {
  padding: { top: 1.875em; bottom: 1.875em; }

  // Medium Desktops
  @include breakpoint(medium) {
    padding: { top: 5em; bottom: 5em; }
  }
}

.c-main__content {
  padding: { top: 1.875em; bottom: 1.875em; }

  // Medium Desktops
  @include breakpoint(medium) {
    padding: { top: 5em; bottom: 5em; }
  }

  &:nth-child(1) {
    padding-top: 0;
  }

  &:nth-child(2) {
    border-top: 1px solid color('gold-medium');
    border-bottom: 1px solid color('gold-medium');
  }

  &:nth-child(3) {
    border-bottom: 1px solid color('gold-medium');
  }
}

///
// About
///
.c-main--about {
  // Medium Desktops
  @include breakpoint(medium) {
    min-height: 1000px;
    background-image: url('../../dist/images/about-full-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img {
    // Medium Desktops
    @include breakpoint(medium) {
      display: none;
    }
  }

  .col-8 {
    // Medium Desktops
    @include breakpoint(medium) {
      @include span(8);
    }
  }
}

///
// Portfolio Single
///
.c-main--portfolio--single {
  text-align: center;
}

///
// Services
///
.c-main--services {
  .col-2 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(2);
    }
  }

  .col-10 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(last 10);
    }
  }
}

///
// Press
///
.c-main--press {
  .col-6 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(6);
    }

    &:last-child {
      // Small Tablets
      @include breakpoint(small) {
        @include span(last 6);
      }
    }
  }
}

///
// Contact
///
.c-main--contact {
  // Medium Desktops
  @include breakpoint(medium) {
    min-height: 630;
    background-image: url('../../dist/images/contact-full-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .col-4 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(4);
    }

    &:last-child {
      // Small Tablets
      @include breakpoint(small) {
        @include span(last 4);
      }
    }
  }
}
