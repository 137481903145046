.home.page {
  .c-portfolio-item {
    &:nth-child(3) {
      // Small Tablets
      @include breakpoint(small) {
        margin-right: 0;
      }
    }

    &:nth-child(4) {
      // Small Tablets
      @include breakpoint(small) {
        margin-left: 18%;
      }
    }
  }
}

.c-portfolio-row {
  @include clearfix;

  /*&:nth-child(2) {
    // Small Tablets
    @include breakpoint(small) {
      text-align: center;
    }

    .c-portfolio-item {
      // Small Tablets
      @include breakpoint(small) {
        float: none;
      }
    }
  }*/
}

.c-portfolio-item {
  // Small Tablets
  @include breakpoint(small) {
    display: inline-block;
    @include span(4);
  }

  img {
    display: block;
    margin: { left: auto; right: auto; }
  }
}

.c-portfolio-item--last {
  // Small Tablets
  @include breakpoint(small) {
    @include span(last 4);
  }
}

.c-portfolio-item__title {
  margin-top: 10px;
  color: color('blue-medium');
  text-align: center;
}
