.c-featured {
  padding: { top: 1.875em; bottom: 1.875em; }
  text-align: center;
  border-top: 1px solid color('gold-medium');
  border-bottom: 1px solid color('gold-medium');

  // Medium Desktops
  @include breakpoint(medium) {
    padding: { top: 3.75em; bottom: 3.75em; }
  }

  .col-6 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(6);
    }

    &:first-child {
      .c-featured__logo {
        margin-bottom: 1.875em;

        // Small Tablets
        @include breakpoint(small) {
          margin-bottom: 0;
        }
      }
    }

    &:last-child {
      // Small Tablets
      @include breakpoint(small) {
        @include span(last 6);
      }
    }
  }
}
