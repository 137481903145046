.c-related {
  padding: { top: 1.875em; bottom: 1.875em; }
  border-top: 1px solid color('gold-medium');

  .c-portfolio-item__title {
    margin-bottom: 0;
  }
}

.c-related__carousel {
  position: relative;

  .owl-dots {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .owl-dot {
    display: inline-block;

    span {
      display: block;
      width: 17px;
      height: 17px;
      margin: 0.3125em;
      background-color: color('blue-medium');
      border-radius: 100%;
    }

    &.active {
      span { background-color: color('gold-medium'); }
    }
  }
}
