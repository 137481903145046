.c-footer {
  margin-bottom: 1.875em;
  padding: { top: 1.875em; bottom: 7.5em; }
  text-align: center;
  background-image: url('../../dist/images/footer-bg.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  // Medium Desktops
  @include breakpoint(medium) {
    padding-top: 5em;
  }

  a {
    font-size: 1.5rem;
  }

  .fa {
    margin: { left: 0.625em; right: 0.625em; }
    font-size: 2rem;
  }
}

.c-footer__copyright {
  font-size: 0.75rem;

  a {
    font-size: 0.75rem;
  }
}
