.c-canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: z('canvas');
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.95);
  transform: translateY(-100%);
  transition: all 0.4s ease-in-out;

  &.is-active {
    transform: translateY(0%);

    li {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.c-canvas__close {
  position: absolute;
  top: 20px;
  left: 30px;
  font-size: 1.25rem;
  font-family: $oswald-regular;
}

.c-canvas__nav {
  padding: 4.375em 1.875em 1.875em;
}
