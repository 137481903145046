///
// Layout Configuration
///
$susy: (
  columns: 12,
  container: 1170px,
  gutters: 1/4,
  global-box-sizing: border-box,
  debug: (
    image: hide,
    color: rgba(#fd6f4f, 0.15)
  )
);

///
// Base global defaults
///
$base-config: (
  padding:      15px,
  font-size:    16px,
  font-family:  sans-serif,
  line-height:  1.5
);

///
// Breakpoints
///
$breakpoints: (
  small:  768px,
  medium: 992px,
  large:  1200px
);

///
// Colors
///
$colors: (
  body:             #1f2e53,
  link:             #1f2e53,
  error:            #f2dede,
  error-message:    #a94442,
  error-border:     #ebccd1,
  success:          #dff0d8,
  success-message:  #3c763d,
  success-border:   #d6e9c6,
  blue-medium:      #1f2e53,
  gold-medium:      #cbaa2c,
  grey-light:       #ebebeb
);

///
// Social Networks
///
$social-networks: (
  facebook:   #3b5998,
  twitter:    #00aced,
  googleplus: #dd4b39,
  linkedin:   #007bb6,
  youtube:    #bb0000,
  instagram:  #517fa4,
  pinterest:  #cb2027,
  flickr:     #ff0084,
  tumblr:     #32506d,
  foursquare: #0072b1,
  dribble:    #ea4c89,
  vine:       #00bf8f
);

///
// Z-index Map for @function z();
///
$z-index: (
  is-sticky: 1000,
  canvas:    9000
);

///
// Fonts
///
$oswald-light: 'oswaldlight';
$oswald-regular: 'oswald_regularregular';
$montaga: 'montagaregular';

///
// Animations
///
@keyframes smallleftSlide {
  0% {
    opacity: 0;
    left: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    left: 43.2%;
    transform: scale(1);
  }
}

@keyframes mediumleftSlide {
  0% {
    opacity: 0;
    left: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    left: 44.9%;
    transform: scale(1);
  }
}

@keyframes largeleftSlide {
  0% {
    opacity: 0;
    left: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    left: 45.5%;
    transform: scale(1);
  }
}

@keyframes smallrightSlide {
  0% {
    opacity: 0;
    right: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    right: 43.2%;
    transform: scale(1);
  }
}

@keyframes mediumrightSlide {
  0% {
    opacity: 0;
    right: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    right: 44.9%;
    transform: scale(1);
  }
}

@keyframes largerightSlide {
  0% {
    opacity: 0;
    right: 10%;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    right: 45.5%;
    transform: scale(1);
  }
}

@keyframes smallfadeIn {
  0% {
    bottom: -10px;
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    bottom: 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mediumfadeIn {
  0% {
    bottom: -35px;
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    bottom: 35px;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes largefadeIn {
  0% {
    bottom: -38px;
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    bottom: 38px;
    opacity: 1;
    transform: scale(1);
  }
}
