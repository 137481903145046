@font-face {
  font-family: 'oswaldlight';
  src: url('../../dist/fonts/oswald-light-webfont.eot');
  src: url('../../dist/fonts/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../dist/fonts/oswald-light-webfont.woff2') format('woff2'),
       url('../../dist/fonts/oswald-light-webfont.woff') format('woff'),
       url('../../dist/fonts/oswald-light-webfont.ttf') format('truetype'),
       url('../../dist/fonts/oswald-light-webfont.svg#oswaldlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oswald_regularregular';
  src: url('../../dist/fonts/oswald-regular-webfont.eot');
  src: url('../../dist/fonts/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../dist/fonts/oswald-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/oswald-regular-webfont.woff') format('woff'),
       url('../../dist/fonts/oswald-regular-webfont.ttf') format('truetype'),
       url('../../dist/fonts/oswald-regular-webfont.svg#oswald_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montagaregular';
  src: url('../../dist/fonts/montaga-regular-webfont.eot');
  src: url('../../dist/fonts/montaga-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../dist/fonts/montaga-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/montaga-regular-webfont.woff') format('woff'),
       url('../../dist/fonts/montaga-regular-webfont.ttf') format('truetype'),
       url('../../dist/fonts/montaga-regular-webfont.svg#montagaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
  font-size: 1.25rem;
  line-height: 2.4;
}

a {
  color: color('link');
  text-decoration: none;

  @include link-states() {
    color: color('gold-medium');
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h2 {
  font-size: 2.25rem;
  font-family: $oswald-regular;
  color: color('gold-medium');
  text-transform: uppercase;
}

h3 {
  font-size: 1.125rem;
  font-family: $oswald-regular;
  color: color('gold-medium');
  text-transform: uppercase;
}

h4 {
  font-size: 1.125rem;
  font-family: $oswald-light;
  font-weight: 300;
  text-transform: uppercase;
}
