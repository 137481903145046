.soliloquy-thumbnails-container {
  margin-top: 1.875em !important;

  .soliloquy-controls { display: none !important; }
}

.soliloquy-outer-container {
  // Small Tablets
  @include breakpoint(small) {
    //max-height: 651px !important;
  }

  .soliloquy-image {
    // Small Tablets
    @include breakpoint(small) {
      //min-height: 651px !important;
      //height: 651px !important;
      //margin: { left: auto !important; right: auto !important; }
    }
  }

  .soliloquy-prev,
  .soliloquy-next {
    background-color: transparent !important;
    background-size: auto !important;
  }

  .soliloquy-prev {
    background-image: url('../../dist/images/arrow-left.png') !important;
  }

  .soliloquy-next {
    background-image: url('../../dist/images/arrow-right.png') !important;
  }
}

.gform_fields {
  margin: { top: 0 !important; bottom: 0 !important; }
  padding-left: 0 !important;
  list-style: none !important;
}

.gfield {
  margin-bottom: 1.25em !important;
}

.gfield_label,
.validation_message,
.gform_validation_container { display: none !important; }

.gfield_error {
  input,
  textarea,
  select {
    background-color: color('error') !important;
  }
}

.gfield_required,
.hs-form-required {
  color: color('error-message') !important;
}

.gform_confirmation_message,
.validation_error {
  margin-bottom: 0.625em !important;
  padding: 0.625em !important;
  border: 1px solid transparent !important;
}

.gform_confirmation_message {
  color: color('success-message') !important;
  background-color: color('success') !important;
  border-color: color('success-border') !important;
}

.hs-error-msgs {
  list-style: none !important;
}

.validation_error,
.hs-error-msgs {
  padding: 0.625em !important;
  color: color('error-message') !important;
  background-color: color('error') !important;
  border-color: color('error-border') !important;
}
