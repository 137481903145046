.c-about {
  padding: { top: 1.875em; bottom: 1.875em; }
  border-top: 1px solid color('gold-medium');

  // Small Tablets
  @include breakpoint(small) {
    background-color: color('grey-light');
    background-image: url('../../dist/images/about-bg-mobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  // Medium Desktops
  @include breakpoint(medium) {
    min-height: 513px;
    padding: { top: 5em; bottom: 5em; }
    background-image: url('../../dist/images/about-bg.jpg');
  }

  .col-8 {
    // Small Tablets
    @include breakpoint(small) {
      @include span(8);
    }
  }

  p {
    font-size: 1.875rem;
    line-height: 1.8;
  }
}
