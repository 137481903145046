::-webkit-input-placeholder {
  color: color('blue-medium');
}

::-moz-placeholder {
  color: color('blue-medium');
}

:-moz-placeholder {
  color: color('blue-medium');
}

:-ms-input-placeholder {
  color: color('blue-medium');
}

input,
textarea {
  width: 100%;
  height: 36px;
  padding: 0.5em;
  border: 1px solid color('gold-medium');
}

textarea {
  min-height: 100px;
}

input[type="submit"] {
  width: auto;
  color: white;
  text-transform: uppercase;
  background-color: color('gold-medium');

  &:hover {
    background-color: color('blue-medium');
    border-color: transparent;
  }
}
