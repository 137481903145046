.home.page {
  .c-header {
    border-top: 1px solid color('gold-medium');

    &.is-sticky {
      border-top-color: transparent;
    }
  }
}

.c-header {
  position: relative;
  padding: { top: 0.9375em; bottom: 0.9375em; }
  background-color: white;
  border-bottom: 1px solid color('gold-medium');

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('is-sticky');
    width: 100%;

    .logged-in & {
      top: 32px;
    }
  }
}

///
// Branding
///
.c-header__logo {
  display: block;
  width: 45px;
  height: 35px;
  background-image: url('../../dist/images/branding-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  // Medium Desktops
  @include breakpoint(medium) {
    float: left;
    width: 174px;
    height: 92px;
    background-image: url('../../dist/images/branding.png');
  }
}

///
// Navgiation Trigger
///
.c-header__nav-trigger {
  display: block;
  position: absolute;
  top: 10px;
  right: 15px;
  //z-index: z('menu-trigger');
  color: color('blue-medium');
  //background-color: transparent;

  // Medium Desktops
  @include breakpoint(medium) {
    display: none;
  }

  &.is-active {
    .c-header__nav-trigger__icon {
      background-color: transparent;

      &:after,
      &:before { top: 0; }

      &:after { transform: rotate(-45deg); }

      &:before { transform: rotate(45deg); }
    }
  }
}

.c-header__nav-trigger__text {
  position: relative;
  top: 4px;
  padding-right: 0.2777777778em;
  font-size: 1.25rem;
  font-family: $oswald-regular;
  text-transform: uppercase;
  vertical-align: top;
}

.c-header__nav-trigger__icon-outer {
  display: inline-block;
  position: relative;
  width: 37px;
  height: 37px;
  border: 3px solid color('gold-medium');
  border-radius: 100%;
}

.c-header__nav-trigger__icon {
  display: inline-block;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  width: 13px;
  height: 3px;
  margin: { left: auto; right: auto; }
  background-color: color('gold-medium');

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 13px;
    height: 3px;
    margin: { left: auto; right: auto; }
    background-color: color('gold-medium');
    transition: transform 0.3s;
  }

  &:after {
    top: 6px;
  }

  &:before {
    bottom: 6px;
  }
}

///
// Navigation
///
.c-header__nav {
  display: none;
  clear: both;

  // Medium Desktops
  @include breakpoint(medium) {
    display: block;
    float: right;
    clear: right;
  }

  &.is-active {
    display: block;
    margin-top: 1.875em;
  }
}
