.c-hero {
  height: 430px;
  padding: { top: 10em; bottom: 3.75em; }
  text-align: center;
  background-image: url('../../dist/images/hero-bg.png');
  background-position: 80% top;
  background-repeat: repeat-x;

  // Medium Desktops
  @include breakpoint(medium) {
    height: 700px;
    padding: { top: 18.75em; bottom: 9.375em; }
    background-position: top left;
  }

  .o-container {
    position: relative;
    height: 100%;
  }
}

.c-hero__left,
.c-hero__right,
.c-hero__text {
  display: none;

  // Small Tablets
  @include breakpoint(small) {
    display: block;
    position: absolute;
    top: 0;
    transition: all 1800ms ease;
  }
}

.c-hero__left {
  // Small Tablets
  @include breakpoint(small) {
    left: 43.2%;
    animation: smallleftSlide 1800ms linear;
  }

  // Medium Desktops
  @include breakpoint(medium) {
    left: 44.9%;
    animation: mediumleftSlide 1800ms linear;
  }

  // Large Desktops
  @include breakpoint(large) {
    left: 45.5%;
    animation: largeleftSlide 1800ms linear;
  }
}

.c-hero__right {
  // Small Tablets
  @include breakpoint(small) {
    right: 43.2%;
    animation: smallrightSlide 1800ms linear;
  }

  // Medium Desktops
  @include breakpoint(medium) {
    right: 44.9%;
    animation: mediumrightSlide 1800ms linear;
  }

  // Large Desktops
  @include breakpoint(large) {
    right: 45.5%;
    animation: largerightSlide 1800ms linear;
  }
}

.c-hero__text {
  // Small Tablets
  @include breakpoint(small) {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin: { left: auto; right: auto; }
    animation: smallfadeIn 1800ms linear;
  }

  // Medium Desktops
  @include breakpoint(medium) {
    bottom: 35px;
    animation: mediumfadeIn 1800ms linear;
  }

  // Large Dekstops
  @include breakpoint(large) {
    bottom: 38px;
    animation: largefadeIn 1800ms linear;
  }
}

.c-hero__full {
  // Small Tablets
  @include breakpoint(small) {
    display: none;
  }
}
