.c-nav__list {
  margin: { top: 0; bottom: 0; }
  padding-left: 0;
  list-style: none;
  @include clearfix;

  // Medium Desktops
  @include breakpoint(medium) {
    margin-top: 1.875em;
  }

  li {
    display: block;
    margin-bottom: 1.25em;
    text-align: center;

    // Mobile
    @media (max-width: 61.9375rem) {
      opacity: 0;
      transform: translateY(150px);
      transition: all 0.8s;
      transform-style: preserve-3d;

      &:nth-child(1) { transition-delay: 0.08s; }

      &:nth-child(2) { transition-delay: 0.16s; }

      &:nth-child(3) { transition-delay: 0.24s; }

      &:nth-child(4) { transition-delay: 0.32s; }

      &:nth-child(5) { transition-delay: 0.40s; }
    }

    // Medium Desktops
    @include breakpoint(medium) {
      display: inline-block;
      float: left;
      width: auto;
      margin: { left: 5.3125em; bottom: 0; }
      text-align: left;
    }

    // Large Desktops
    @include breakpoint(large) {
      margin-left: 7.5em;
    }
  }

  .active {
    a {
      color: color('gold-medium');
    }
  }

  .menu-contact {
    margin-bottom: 0;
  }

  a {
    font-size: 1.25rem;
    font-family: $oswald-regular;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
